import * as React from "react"
import Layout from "../../layout"
import { NarrowSingleColumn } from "gatsby-theme-dashboard/src/modules/ui/layouts/Containers"
import { Heading, Text, Card, ThemeCss, Link } from "gatsby-interface"
import SubmissionForm, {
  FormValues,
} from "../../components/starters/community-submissions/SubmissionForm"
import axios from "axios"
import Guidelines from "../../components/starters/community-submissions/guidelines.md"
import { FormikHelpers } from "formik"

const STARTER_SUBMISSION_API_ENDPOINT = `/api/community-starter-submission`
const mainCss: ThemeCss = (theme) => ({
  display: `flex`,
  flexDirection: `column`,
  gap: theme.space[7],
})

const sectionCss: ThemeCss = (theme) => ({
  padding: theme.space[8],
})
const cardCss: ThemeCss = (theme) => ({
  display: `flex`,
  flexDirection: `column`,
  gap: theme.space[5],
  padding: theme.space[8],
})

const CommunitySubmissions = () => {
  const [formSubmitted, setFormSubmitted] = React.useState(false)

  const handleSubmit = (
    values: FormValues,
    { resetForm }: FormikHelpers<FormValues>
  ) => {
    return axios.post(STARTER_SUBMISSION_API_ENDPOINT, values).then((data) => {
      if (data.status === 200) {
        setFormSubmitted(true)
        resetForm()
      }
    })
  }

  return (
    <Layout>
      <NarrowSingleColumn>
        <main css={mainCss}>
          <Heading as="h1">Gatsby Starter Community Submissions</Heading>
          <section css={sectionCss}>
            <Guidelines />
          </section>
          <Card css={cardCss}>
            {!formSubmitted ? (
              <SubmissionForm onSubmit={handleSubmit} />
            ) : (
              <SubmissionConfirmation setFormSubmitted={setFormSubmitted} />
            )}
          </Card>
        </main>
      </NarrowSingleColumn>
    </Layout>
  )
}

type SubmissionConfirmationProps = {
  setFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>
}

const SubmissionConfirmation = ({
  setFormSubmitted,
}: SubmissionConfirmationProps): JSX.Element => (
  <React.Fragment>
    <Heading as="h2">Thanks for your contribution!</Heading>
    <Text>
      We have received your Starter submission! We review submissions each month
      and will send you an update via email following our review.
    </Text>
    <Link
      css={{ cursor: `pointer` }}
      onClick={(e) => {
        e.preventDefault()
        setFormSubmitted(false)
      }}
    >
      Submit Another Starter
    </Link>
    <Link to="/starters">&larr; Back to Starters Library</Link>
  </React.Fragment>
)

export default CommunitySubmissions
