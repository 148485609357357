import * as React from "react"
import {
  Button,
  InputConnectedField,
  TextAreaConnectedField,
} from "gatsby-interface"
import { MdArrowForward } from "react-icons/md"
import * as Yup from "yup"
import { ui as uiText } from "gatsby-theme-dashboard/src/modules/locales/default"
import { Form } from "gatsby-theme-dashboard/src/modules/form/components/Form"
import { FormikHelpers } from "formik"

export type FormValues = {
  email: string
  repoUrl: string
  demoUrl: string
  screenshotUrl: string
  description: string
  features: string
}

const validationSchema = Yup.object().shape<FormValues>({
  email: Yup.string()
    .email(uiText.messages.validationInvalidEmail)
    .required(uiText.messages.validationIsRequired),
  repoUrl: Yup.string()
    .url(`Must be a valid URL`)
    .matches(/github\.com\/.+\/.+/, `Must be a valid Github Repository URL`)
    .required(uiText.messages.validationIsRequired),
  demoUrl: Yup.string()
    .url(`Must be a valid URL`)
    .required(uiText.messages.validationIsRequired),
  screenshotUrl: Yup.string()
    .url(`Must be a valid URL`)
    .required(uiText.messages.validationIsRequired),
  description: Yup.string().required(uiText.messages.validationIsRequired),
  features: Yup.string().required(uiText.messages.validationIsRequired),
})

export type SubmissionFormProps = {
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  void | Promise<any>
}

export default function SubmissionForm({ onSubmit }: SubmissionFormProps) {
  return (
    <Form<FormValues>
      validationSchema={validationSchema}
      initialValues={{
        email: "",
        repoUrl: "",
        demoUrl: "",
        screenshotUrl: "",
        description: "",
        features: "",
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form.FormElement applySpacing noValidate>
          <InputConnectedField name="email" label="Contact email" required />
          <InputConnectedField
            name="repoUrl"
            label="Github repository URL"
            required
          />
          <InputConnectedField
            name="demoUrl"
            label="Gatsby Cloud demo URL"
            required
          />
          <InputConnectedField
            name="screenshotUrl"
            label="Demo screenshot URL"
            required
          />
          <TextAreaConnectedField
            name="description"
            label="Description of Starter"
            placeholder="A 1-3 sentence description of your Starter."
            required
          />
          <TextAreaConnectedField
            name="features"
            label="Significant features and plugins (1 per line)"
            placeholder="A list of relevant features or plugins that this Starter uses. These terms will help people find your Starter when searching the library."
            required
          />
          <Button
            type="submit"
            loading={isSubmitting}
            rightIcon={<MdArrowForward />}
          >
            Submit Starter
          </Button>
        </Form.FormElement>
      )}
    </Form>
  )
}
