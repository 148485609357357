import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "starters/submissions"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We would be happy to include your Starter as part of the Gatsby Community Starters Library! We review and add new starters to the Library on a monthly basis.`}</p>
    <p>{`In order to be considered, your Starter should meet these minimum requirements:`}</p>
    <ul>
      <li parentName="ul">{`Be on the `}<a parentName="li" {...{
          "href": "/docs/reference/release-notes/"
        }}>{`latest major version of Gatsby`}</a></li>
      <li parentName="ul">{`Build without error on Gatsby Cloud`}</li>
      <li parentName="ul">{`Have a demo deployed to Gatsby Cloud`}</li>
      <li parentName="ul">{`Demonstrate best practices around accessibility`}
        <ul parentName="li">
          <li parentName="ul">{`For details and resources on accessibility standards, we recommend `}<a parentName="li" {...{
              "href": "https://a11y.coffee/"
            }}>{`https://a11y.coffee/`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Demonstrate best practices around performance`}
        <ul parentName="li">
          <li parentName="ul">{`For details and resources on performance, see:`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://web.dev/lighthouse-performance/"
            }}>{`Front-end performance (link)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/docs/how-to/performance/improving-build-performance/"
            }}>{`Gatsby build performance (link)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Include documentation on installation and contribution`}</li>
    </ul>
    <p>{`In addition to the guidelines above, we will also give preference to Starters that demonstrate use-cases, functionality, or designs not covered by existing Starters.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      